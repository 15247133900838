import fetch from 'axios';
import {serviceDomain} from '@/api/finder';

export function getTableList (params) {
  return fetch.get( `${serviceDomain}/virtual/schema/list`, {params});
}

export function getTableField (id) {
  return fetch.get(`${serviceDomain}/virtual/schema/list/${id}`);
}

export function tablePreview (ids) {
  return fetch.post(`${serviceDomain}/virtual/schema/preview`, { sourceSchemaId: ids });
}

export function getDataTableSource (params) {
  return fetch.get(`${serviceDomain}/virtual/schema/source/list`, { params });
}

/**
 * 创建 或者 修改
 * @param data
 */
export function tableSave (data) {
  return fetch.post(`${serviceDomain}/virtual/schema/save`, data, {headers: {
      'Content-Type': 'application/json'
    }});
}

/**
 * 删除虚拟表
 * @param schemaId 传入则删除整个虚拟表
 * @param {Array} columnSchemaList 传入字段则只删除字段
 */
export function removeTable ({ schemaId, columnSchemaList }) {
  return fetch.post('/virtual/schema/delete', {
    schemaId, columnSchemaList
  }, {
    headers: {'Content-Type': 'application/json'}
  });
}
