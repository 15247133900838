var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-field-setting"},[_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_vm._v(" 新增字段 ")])],1),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.selection.length !== 0),expression:"selection.length !== 0"}],staticStyle:{"margin-bottom":"10px"},attrs:{"size":"small"},on:{"click":_vm.handleBatchRemove}},[_vm._v(" 批量删除 ")]),_c('el-table',{attrs:{"data":_vm.dataSource,"max-height":"90%","highlight-current-row":"","border":""},on:{"selection-change":_vm.handleSelectChange}},[_c('el-table-column',{attrs:{"type":"selection"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"序号","prop":"id"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"字段列名","prop":"columnName","width":"221"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"字段列名","size":"mini"},model:{value:(row.columnName),callback:function ($$v) {_vm.$set(row, "columnName", $$v)},expression:"row.columnName"}})]}}])}),_c('el-table-column',{attrs:{"label":"字段描述","prop":"columnNameLabel","width":"221"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"字段描述","size":"mini"},model:{value:(row.columnNameLabel),callback:function ($$v) {_vm.$set(row, "columnNameLabel", $$v)},expression:"row.columnNameLabel"}})]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"类型","prop":"columnType"}}),_c('el-table-column',{attrs:{"label":"列表","prop":"displayInTable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"true-label":"1","false-label":"0"},model:{value:(row.displayInTable),callback:function ($$v) {_vm.$set(row, "displayInTable", $$v)},expression:"row.displayInTable"}})]}}])}),_c('el-table-column',{attrs:{"label":"查询","prop":"isSearch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"true-label":"1","false-label":"0"},model:{value:(row.isSearch),callback:function ($$v) {_vm.$set(row, "isSearch", $$v)},expression:"row.isSearch"}})]}}])}),_c('el-table-column',{attrs:{"label":"过滤","prop":"isFilter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"true-label":"1","false-label":"0"},model:{value:(row.isFilter),callback:function ($$v) {_vm.$set(row, "isFilter", $$v)},expression:"row.isFilter"}})]}}])}),_c('el-table-column',{attrs:{"label":"表单","prop":"isRequire"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"true-label":"1","false-label":"0"},model:{value:(row.displayInForm),callback:function ($$v) {_vm.$set(row, "displayInForm", $$v)},expression:"row.displayInForm"}})]}}])}),_c('el-table-column',{attrs:{"label":"必填","prop":"isRequire"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"true-label":"1","false-label":"0"},model:{value:(row.isRequire),callback:function ($$v) {_vm.$set(row, "isRequire", $$v)},expression:"row.isRequire"}})]}}])}),_c('el-table-column',{attrs:{"label":"显示类型","prop":"columnDisplayType","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"size":"mini"},model:{value:(row.columnDisplayType),callback:function ($$v) {_vm.$set(row, "columnDisplayType", $$v)},expression:"row.columnDisplayType"}},_vm._l((_vm.displayType),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"排序","prop":"orderSort","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{staticStyle:{"width":"80px"},attrs:{"controls":false,"size":"mini"},model:{value:(row.orderSort),callback:function ($$v) {_vm.$set(row, "orderSort", $$v)},expression:"row.orderSort"}})]}}])}),_c('el-table-column',{attrs:{"label":"关联字典","prop":"dictCode"}}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.handleRemoveRow(row)}}},[_vm._v(" 删除 ")])]}}])})],1),_c('div',[_c('br'),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleSaveChange}},[_vm._v(" 保存更改 ")])],1),_c('el-dialog',{attrs:{"visible":_vm.visible,"title":"新增字段"},on:{"click":_vm.closeAddField},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.closeAddField}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleAddField}},[_vm._v(" 保存 ")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.fieldFormModel,"rules":_vm.fieldFormRule,"label-width":"80px","label-position":"left"}},[_c('el-form-item',{attrs:{"prop":"columnName","label":"字段名称"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入字段名称"},model:{value:(_vm.fieldFormModel.columnName),callback:function ($$v) {_vm.$set(_vm.fieldFormModel, "columnName", $$v)},expression:"fieldFormModel.columnName"}})],1),_c('el-form-item',{attrs:{"label":"描述"}},[_c('el-input',{attrs:{"rows":4,"type":"textarea","size":"small","placeholder":"请输入字段描述"},model:{value:(_vm.fieldFormModel.columnNameLabel),callback:function ($$v) {_vm.$set(_vm.fieldFormModel, "columnNameLabel", $$v)},expression:"fieldFormModel.columnNameLabel"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }