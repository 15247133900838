<template>
  <div class="table-field-setting">
    <div style="margin-bottom: 20px">
      <el-button size="small" type="primary" @click="visible = true">
        新增字段
      </el-button>
    </div>
    <el-button v-show="selection.length !== 0" size="small" style="margin-bottom: 10px" @click="handleBatchRemove">
      批量删除
    </el-button>
    <el-table :data="dataSource" max-height="90%" highlight-current-row border @selection-change="handleSelectChange">
      <el-table-column type="selection" />
      <el-table-column show-overflow-tooltip label="序号" prop="id" />
      <el-table-column show-overflow-tooltip label="字段列名" prop="columnName" width="221">
        <template #default="{row}">
          <el-input v-model="row.columnName" placeholder="字段列名" size="mini" style="width: 200px" />
        </template>
      </el-table-column>
      <el-table-column label="字段描述" prop="columnNameLabel" width="221">
        <template #default="{row}">
          <el-input v-model="row.columnNameLabel" placeholder="字段描述" size="mini" style="width: 200px" />
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="类型" prop="columnType" />
      <el-table-column label="列表" prop="displayInTable">
        <template v-slot:default="{row}">
          <el-checkbox v-model="row.displayInTable" true-label="1" false-label="0" />
        </template>
      </el-table-column>
      <el-table-column label="查询" prop="isSearch">
        <template v-slot:default="{row}">
          <el-checkbox v-model="row.isSearch" true-label="1" false-label="0" />
        </template>
      </el-table-column>
      <el-table-column label="过滤" prop="isFilter">
        <template v-slot:default="{row}">
          <el-checkbox v-model="row.isFilter" true-label="1" false-label="0" />
        </template>
      </el-table-column>
      <el-table-column label="表单" prop="isRequire">
        <template v-slot:default="{row}">
          <el-checkbox v-model="row.displayInForm" true-label="1" false-label="0" />
        </template>
      </el-table-column>
      <el-table-column label="必填" prop="isRequire">
        <template v-slot:default="{row}">
          <el-checkbox v-model="row.isRequire" true-label="1" false-label="0" />
        </template>
      </el-table-column>
      <el-table-column label="显示类型" prop="columnDisplayType" width="120">
        <template v-slot:default="{row}">
          <el-select v-model="row.columnDisplayType" size="mini">
            <el-option v-for="item in displayType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="排序" prop="orderSort" width="120">
        <template v-slot:default="{row}">
          <el-input-number v-model="row.orderSort" :controls="false" size="mini" style="width: 80px" />
        </template>
      </el-table-column>
      <el-table-column label="关联字典" prop="dictCode" />
      <el-table-column label="操作" fixed="right">
        <template v-slot:default="{row}">
          <el-button size="small" type="text" @click="handleRemoveRow(row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <br>
      <el-button type="primary" size="small" @click="handleSaveChange">
        保存更改
      </el-button>
    </div>
    <el-dialog :visible="visible" title="新增字段" @click="closeAddField">
      <el-form ref="form" :model="fieldFormModel" :rules="fieldFormRule" label-width="80px" label-position="left">
        <el-form-item prop="columnName" label="字段名称">
          <el-input v-model="fieldFormModel.columnName" size="small" placeholder="请输入字段名称" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="fieldFormModel.columnNameLabel" :rows="4" type="textarea" size="small" placeholder="请输入字段描述" />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <el-button size="small" @click="closeAddField">
          取消
        </el-button>
        <el-button size="small" type="primary" @click="handleAddField">
          保存
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {getTableField, removeTable, tableSave} from '@/api/table-set';

export default {
  name: 'TableFieldSetting',
  data () {
    return {
      visible: false,
      fieldFormModel: {
        columnName: '',
        columnNameLabel: ''
      },
      fieldFormRule: {
        columnName: [{ required: true, message: '请输入字段名称' }]
      },
      displayType: [
        { label: '文本框', value: '0' },
        { label: '文本域', value: '1' },
        { label: '下拉框', value: '2' },
        { label: '单选框', value: '3' },
        { label: '复选框', value: '4' },
        { label: '日期控件', value: '5' },
        { label: '图片上传', value: '6' },
        { label: '文件上传', value: '7' },
      ],
      dataSource: [],
      detail: {},
      selection: []
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    async handleAddField () {
      await this.$refs.form.validate();
      this.dataSource.unshift({
        ...this.fieldFormModel,
        columnDisplayType: '0',
        displayInForm: '0',
        displayInTable: '0',
        isFilter: '0',
        isPk: '0',
        isRequire: '0',
        isSearch: '0',
        orderSort: 0
      });
      await this.handleSaveChange();
      this.closeAddField();
    },
    closeAddField () {
      this.visible = false;
      this.fieldFormModel = {
        columnName: '',
        remark: ''
      };
    },
    async init () {
      const id = this.$route.params.id;
      const res = await getTableField(id);
      this.detail = res;
      this.dataSource = res.columnSchemaList;
    },
    async handleRemoveRow (row) {
      await this.$confirm('确定删除当前行吗?');
      await removeTable({
        columnSchemaList: [
          {columnSchemaId: row.id}
        ]
      });
      this.init();
    },
    async handleSaveChange () {
      await tableSave({
        ...this.detail,
        columnSchemaList: this.dataSource
      });
      this.init();
      this.$message.success('保存成功');
    },
    handleSelectChange (selection) {
      this.selection = selection;
    },
    async handleBatchRemove () {
      await this.$confirm('确定删除所选行吗?');
      const columnSchemaList = this.selection.map(item => ({columnSchemaId: item.id}));
      await removeTable({ columnSchemaList: columnSchemaList });
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
.table-field-setting {
  background: #ffffff;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 20px;
}
</style>
